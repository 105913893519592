<template lang="pug">
    span
        a.link(v-show="hasState" :href="href" :title="'default.REFERENCETYPE'|translate" target="_blank") {{id}}
        span(v-show="!hasState && id") {{id}}
</template>

<script>
    export default {
        name: 'OptiRefId',
        props: {
            section: {
                type: String,
                required: true
            },
            typeId: {
                type: Number,
                required: true,
            },
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                hasState: false,
                data: {
                    'document-type': {
                        1: 'app.warehouse.order.details',
                        2: 'app.sale.details',
                        3: 'app.warehouse.transfer.detail',
                        4: 'app.warehouse.return.details',
                        5: 'app.task.detail',
                        6: 'app.tasks.reclamation.details',
                        8:  'app.sale-offstock.details',
                        10: 'app.eShop.order',
                        11: 'app.warehouse.return.details',
                        12: 'app.sale.reclamation.details',
                        13: 'app.regeneration.details',
                        14: 'app.prices.change'
                    },
                    'product-history': {
                        1: 'app.warehouse.order.details',
                        2: 'app.sale.details',
                        3: 'app.warehouse.transfer.detail',
                        4: 'app.warehouse.return.details',
                        5: 'app.task.detail',
                        7: 'app.tasks.wz.details',
                        8: 'app.sale-offstock.details',
                        9: 'app.warehouse.smile.details',
                        10: 'app.eShop.order',
                        11: 'app.register.return.sale.details',
                        12: 'app.sale.reclamation.details',
                        14: 'app.prices.change',
                        15: 'app.warehouse.order.wz.details',
                        100: 'app.catalog.stock-correction.edit'
                    },
                    'loyality-card': {
                        1: 'app.task.detail',
                        2: 'app.task.detail',
                        3: 'app.task.detail',
                        4: 'app.task.detail',
                        5: 'app.sale.details',
                        6: 'app.eShop.order',
                        7: 'app.sale-offstock.details',
                        8: 'app.register.return.sale.details',
                        9: 'app.regeneration.details'
                    },
                    receipt: {
                        1: 'app.task.detail',
                        2: 'app.task.detail',
                        3: 'app.sale.details',
                        4: 'app.sale-offstock.details',
                        5: 'app.register.return.sale.details',
                        6: 'app.task.detail',
                        9: 'app.sale.reclamation.details',
                        10: 'app.regeneration.details',
                        11: 'app.regeneration.details',
                        12: 'app.regeneration.details'
                    },
                    'shipment-item': {
                        1: 'app.warehouse.order.details',
                        2: 'app.warehouse.return.details',
                        4: 'app.task.detail',
                        9: 'app.eShop.order',
                        12: 'app.tasks.reclamation.details',
                        13: 'app.warehouse.transfer.detail',
                        14: 'app.sale.reclamation.details',
                        15: 'app.regeneration.details',
                        16: 'app.warehouse.ticket.details',
                        17: 'app.coupon.details'
                    },
                    transfer: {
                        2: 'app.sale.details',
                        3: 'app.warehouse.transfer.detail',
                        5: 'app.task.detail',
                        6: 'app.tasks.reclamation.details',
                        7: 'app.sale.reclamation.details',
                        10: 'app.eShop.order',
                        11: 'app.warehouse.transfer.detail'
                    },
                    regeneration: {
                        1: 'app.task.detail',
                        2: 'app.sale.details'
                    },
                    mail: {
                        1: 'app.catalog.offer-redirect'
                    },
                    rate: {
                        1: 'app.catalog.offer-redirect',
                        2: 'app.catalog.offer-temporary-task'
                    }
                }
            }
        },

        computed: {
            href() {
                if(!this.data[this.section]) {
                    console.error(`Section '${this.section}' not found @ OptiRefId`)
                    return null
                }

                if (!this.data[this.section] || !this.typeId || !this.id) {
                    return null
                }

                let section = this.data[this.section]
                this.hasState = Object.keys(section).includes(this.typeId.toString())

                return this.hasState ? this.$state.href(section[this.typeId.toString()], {id: this.id}) : null
            }
        }
    }
</script>

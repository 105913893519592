<template lang="pug">
    .row
        .col-xs-12
            .form.form-horizontal
                .form-group
                    .col-sm-6(style="margin-bottom:10px")
                        input#eOrderId.form-control(name="eOrderId" :placeholder="'eShop.migrate.PLACEHOLDER'|translate"
                            v-model="eOrderId")
                        OptiValidate(f="eOrderId" :data="$v.eOrderId")
                    .col-sm-6.text-center-xs(style="margin-bottom:10px")
                        OptiButton(type="success" :disabled="$v.$invalid" @click.native="migrate") {{'eShop.migrate.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-cloud-download

        .col-xs-12(v-if="!loading && (result.errors.length || result.messages.length)")
            .alert.alert-danger(v-show="result.errors.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'eShop.migrate.alert.ERRORS'|translate}}

                ul
                    li(v-for="error in result.errors" v-html="error")

            .alert.alert-success(v-show="result.messages.length")
                i.fa.fa-check-circle.mr3
                span {{'eShop.migrate.alert.MESSAGES'|translate}}

                ul
                    li(v-for="message in result.messages" v-html="message")

            .text-center(v-show="result.messages.length")
                OptiButton(type="success" @click.native="$state.go('app.eShop.order', {id: eOrderId})"
                    ) {{'eShop.migrate.GO_TO_NEW_E_ORDER'|translate}}
                    template(#icon)
                        i.fa.fa-arrow-right

        .col-xs-12(v-else)
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators'
    import {numeric} from 'vuelidate/lib/validators'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEShopMigrate',
        components: {
            OptiDimmer,
            OptiValidate,
            OptiButton
        },
        data() {
            return {
                eOrderId: '',
                result: {
                    errors: [],
                    messages: []
                },

                loading: false
            }
        },
        validations: {
            eOrderId: {
                noZero,
                numeric
            }
        },
        methods: {
            migrate() {
                this.loading = true

                API.get('e-shop/migrate/' + this.eOrderId).then(res => {
                    this.result = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('eShop.migrate.notify.error.MIGRATE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    ul {
        list-style-type: square;
    }
</style>
import Vue from 'vue'

// dyrektywy
import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
import OptiSelectCity from '@/js/app/vue/components/Select/OptiSelectCity'
import OptiSelectCategoryPriceGroup from '@/js/app/vue/components/Select/OptiSelectCategoryPriceGroup'
import OptiSelectPermissions from '@/js/app/vue/components/Select/OptiSelectPermissions'

import OptiCheckboxUser from '../vue/components/Checkbox/OptiCheckboxUser'
import OptiCheckboxDepartment from '../vue/components/Checkbox/OptiCheckboxDepartment'

import OptiMessageMember from '../vue/components/Messages/OptiMessageMember'

import OptiReceiptNotice from '../vue/components/Receipt/Notice/OptiReceiptNotice'
import OptiReceiptNoticeValue from '../vue/components/Receipt/Notice/OptiReceiptNoticeValue'

import OptiSaleSearch from '../vue/components/Sales/SaleSearch/OptiSaleSearch'

import OptiRate from '../vue/components/Rate/OptiRate'
import OptiReportOffersRate from '@/js/app/vue/components/Reports/Offers/OptiReportOffersRate'

import OptiAlertRedirect from '@/js/app/vue/components/OptiAlertRedirect'

// Reclamations
import OptiReclamationTaskAdd from '../vue/components/Reclamations/Tasks/OptiReclamationTaskAdd'
import OptiReclamationTaskEdit from '../vue/components/Reclamations/Tasks/OptiReclamationTaskEdit'
import OptiReclamationTaskDetails from '../vue/components/Reclamations/Tasks/Details/OptiReclamationTaskDetails'

import OptiReclamationSaleAdd from '../vue/components/Reclamations/Sales/OptiReclamationSaleAdd'
import OptiReclamationSaleEdit from '../vue/components/Reclamations/Sales/OptiReclamationSaleEdit'
import OptiReclamationSaleDetails from '../vue/components/Reclamations/Sales/Details/OptiReclamationSaleDetails'

// Regenerations
import OptiRegenerationAdd from '../vue/components/Regenerations/OptiRegenerationAdd.vue'
import OptiRegenerationEdit from '../vue/components/Regenerations/OptiRegenerationEdit.vue'
import OptiRegenerationDetails from '../vue/components/Regenerations/Details/OptiRegenerationDetails'
import OptiRegenerationNotes from '../vue/components/Regenerations/OptiRegenerationNotes'

import OptiButtonToggle from '@/js/app/vue/components/Button/OptiButtonToggle'
import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
import OptiButtonPrescription from '@/js/app/vue/components/Button/OptiButtonPrescription'
import OptiButtonReportRate from '@/js/app/vue/components/Button/OptiButtonReportRate'

import OptiPayment from '@/js/app/vue/components/Payments/OptiPayment'
import OptiPaymentShow from '@/js/app/vue/components/Payments/OptiPaymentShow'

import OptiChartEmployeeCorrectedReachedBudget from '@/js/app/vue/components/Chart/OptiChartEmployeeCorrectedReachedBudget'
import OptiChartDepartmentBudget from '@/js/app/vue/components/Chart/OptiChartDepartmentBudget'
import OptiChartSummaryBudget from '@/js/app/vue/components/Chart/OptiChartSummaryBudget'
import OptiChartDiscounts from '@/js/app/vue/components/Chart/OptiChartDiscounts'
//import OptiChartExpenses from '../vue/components/Chart/OptiChartExpenses'

import BonusShow from '../vue/components/Bonus/BonusShow'
import OptiBonusEdit from '@/js/app/vue/components/Departments/OptiBonusEdit'

import OptiScheduleNavigation from '@/js/app/vue/components/Schedule/Navigation/OptiScheduleNavigation'
import OptiScheduleWeek from '@/js/app/vue/components/Schedule/Week/OptiScheduleWeek'
import OptiScheduleMonthQuarter from '@/js/app/vue/components/Schedule/OptiScheduleMonthQuarter'
import OptiScheduleMonthExcel from '@/js/app/vue/components/Schedule/OptiScheduleMonthExcel'

import OptiSummaryEntriesReport from '@/js/app/vue/components/Chart/OptiSummaryEntriesReport'

import OptiTimer from '@/js/app/vue/components/Timer/OptiTimer'
import OptiCountdown from '@/js/app/vue/components/Timer/OptiCountdown'

import OptiSmileMoreDetails from '@/js/app/vue/components/Warehouse/Smile/OptiSmileMoreDetails'

import OptiProductDetails from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductDetails'
import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
import OptiDepositoryInfo from '@/js/app/vue/components/Warehouse/Products/OptiDepositoryInfo'
import OptiProductEdit from '@/js/app/vue/components/Warehouse/Products/Edit/OptiProductEdit'
import OptiProductExport from '@/js/app/vue/components/Warehouse/Products/OptiProductExport'
import OptiProductImport from '@/js/app/vue/components/Warehouse/Products/OptiProductImport'
import OptiProductDelivery from '@/js/app/vue/components/Warehouse/Products/OptiProductDelivery'
import OptiProductPriceChangeEdit from '@/js/app/vue/components/Warehouse/Products/PriceChanges/OptiProductPriceChangeEdit'
import OptiProductPriceChangeAdd from '@/js/app/vue/components/Warehouse/Products/PriceChanges/OptiProductPriceChangeAdd'
import OptiProductPriceChangeImport from '@/js/app/vue/components/Warehouse/Products/PriceChanges/OptiProductPriceChangeImport'
import OptiProductPriceChangeProduct from '@/js/app/vue/components/Warehouse/Products/PriceChanges/OptiProductPriceChangeProduct'
import OptiProductStockChangeEdit from '@/js/app/vue/components/Warehouse/Products/StockChanges/OptiProductStockChangeEdit'
import OptiProductLp from '@/js/app/vue/components/Warehouse/Products/OptiProductLp'

import OptiInventoryDetails from '@/js/app/vue/components/Warehouse/Inventories/OptiInventoryDetails'
import OptiInventoryAdd from '@/js/app/vue/components/Warehouse/Inventories/OptiInventoryAdd'
import OptiInventoryTasksInProgress from '@/js/app/vue/components/Warehouse/Inventories/OptiInventoryTasksInProgress'

import OptiInventoryQuickDetails from '@/js/app/vue/components/Warehouse/Inventories/Quick/OptiInventoryQuickDetails'
import OptiInventoryQuickAdd from '@/js/app/vue/components/Warehouse/Inventories/Quick/OptiInventoryQuickAdd'

import OptiEnum from '../vue/components/OptiEnum'
import OptiNotes from '../vue/components/OptiNotes'
import OptiScrollTop from '../vue/components/OptiScrollTop'
import OptiTaskIcon from '../vue/components/Tasks/OptiTaskIcon'
import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
import OptiDownloading from '@/js/app/vue/components/OptiDownloading'
import OptiRefId from '../vue/components/OptiRefId'
import OptiPreviousLenses from '../vue/components/PreviousLenses/OptiPreviousLenses'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
//import OptiTable from '@/js/app/vue/components/Table/Base/OptiTable'

import OptiLeftMenu from '../vue/components/Menu/OptiLeftMenu'
import OptiSearchMenu from '../vue/components/Menu/OptiSearchMenu'
import OptiSearchResult from '@/js/app/vue/components/Menu/OptiSearchResult'
import OptiDashboard from '../vue/components/Dashboard/OptiDashboard'

import OptiTaskDetails from '@/js/app/vue/components/Tasks/OptiTaskDetails'

import OptiTaskComparePreview from '../vue/components/Tasks/Compare/OptiTaskComparePreview'

// całe moduły
import OptiCarts from '@/js/app/vue/components/Carts/OptiCarts'
import OptiCartSaleAdd from '@/js/app/vue/components/Carts/Add/OptiCartSaleAdd'
import OptiCartOrderAdd from '@/js/app/vue/components/Carts/Add/OptiCartOrderAdd'
import OptiCartSendbackAdd from '@/js/app/vue/components/Carts/Add/OptiCartSendbackAdd'

import OptiSendbackDetails from '@/js/app/vue/components/Warehouse/OptiSendbackDetails'

import OptiUserAdd from '../vue/components/Users/OptiUserAdd'
import OptiUserEdit from '../vue/components/Users/OptiUserEdit'

import OptiUsersPermissionExtraAdd from '@/js/app/vue/components/Users/PermissionExtras/OptiUsersPermissionExtraAdd'
import OptiUsersPermissionExtraEdit from '@/js/app/vue/components/Users/PermissionExtras/OptiUsersPermissionExtraEdit'

import OptiUsersExchangeAdd from '@/js/app/vue/components/Users/Exchanges/OptiUsersExchangeAdd'
import OptiUsersExchangeEdit from '@/js/app/vue/components/Users/Exchanges/OptiUsersExchangeEdit'
import OptiUsersExchangeDetails from '@/js/app/vue/components/Users/Exchanges/OptiUsersExchangeDetails'

import OptiUsersTrainingAdd from '@/js/app/vue/components/Users/Trainings/OptiUsersTrainingAdd'
import OptiUsersTrainingEdit from '@/js/app/vue/components/Users/Trainings/OptiUsersTrainingEdit'
import OptiUsersTrainingDetails from '@/js/app/vue/components/Users/Trainings/OptiUsersTrainingDetails'
import OptiUsersTrainingSchedule from '@/js/app/vue/components/Users/Trainings/Schedule/OptiUsersTrainingSchedule'

import OptiRoleAdd from '../vue/components/Users/Roles/OptiRoleAdd'
import OptiRoleEdit from '../vue/components/Users/Roles/OptiRoleEdit'
import OptiRoleAssign from '../vue/components/Users/Roles/Assign/OptiRoleAssign'
import OptiRoleExceptionAdd from '../vue/components/Users/Roles/Exceptions/OptiRoleExceptionAdd'

import OptiCatalogCategory from '@/js/app/vue/components/Catalog/OptiCatalogCategory'
import OptiCatalogCategoryEdit from '@/js/app/vue/components/Catalog/Edit/OptiCatalogCategoryEdit'
import OptiCatalogOffer from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOffer'
import OptiCatalogOfferRedirect from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferRedirect'
import OptiCatalogOfferPreview from '@/js/app/vue/components/Catalog/Offer/Preview/OptiCatalogOfferPreview'
import OptiCatalogPricesConfirm from '@/js/app/vue/components/Catalog/OptiCatalogPricesConfirm'

import OptiDepartmentsGroupAdd from '@/js/app/vue/components/Departments/Groups/OptiDepartmentsGroupAdd'
import OptiDepartmentsGroupEdit from '@/js/app/vue/components/Departments/Groups/OptiDepartmentsGroupEdit'

import OptiDepartmentsListAdd from '@/js/app/vue/components/Departments/List/OptiDepartmentsListAdd'
import OptiDepartmentsListEdit from '@/js/app/vue/components/Departments/List/OptiDepartmentsListEdit'

import OptiDepartmentsOfficeAdd from '@/js/app/vue/components/Departments/Offices/OptiDepartmentsOfficeAdd'
import OptiDepartmentsOfficeDetails from '@/js/app/vue/components/Departments/Offices/OptiDepartmentsOfficeDetails'
import OptiDepartmentsOfficeEdit from '@/js/app/vue/components/Departments/Offices/OptiDepartmentsOfficeEdit'

import OptiPatientFind from '@/js/app/vue/components/Patients/OptiPatientFind'
import OptiPatientAdd from '@/js/app/vue/components/Patients/OptiPatientAdd'
import OptiPatientEdit from '@/js/app/vue/components/Patients/OptiPatientEdit'

import OptiPatientsDetails from '@/js/app/vue/components/Patients/Details/OptiPatientsDetails'

import OptiPatientsDetailsPrescriptionsMatching from '@/js/app/vue/components/Patients/Details/Prescriptions/OptiPatientsDetailsPrescriptionsMatching'
import OptiPatientLoyalityCard from '@/js/app/vue/components/Patients/LoyalityCard/OptiPatientLoyalityCard'
import OptiPatientsDetailsLoyalityCardsOrder
    from '@/js/app/vue/components/Patients/Details/LoyalityCards/OptiPatientsDetailsLoyalityCardsOrder'
import OptiPatientsDetailsLoyalityCardsEventAdd
    from '@/js/app/vue/components/Patients/Details/LoyalityCards/Event/OptiPatientsDetailsLoyalityCardsEventAdd'
import OptiPatientsDetailsLoyalityCardsEventEdit
    from '@/js/app/vue/components/Patients/Details/LoyalityCards/Event/OptiPatientsDetailsLoyalityCardsEventEdit'

import OptiPatientsDetailsPrescriptionAdd from '@/js/app/vue/components/Patients/Prescriptions/OptiPatientsDetailsPrescriptionAdd'
import OptiPatientsDetailsPrescriptionEdit from '@/js/app/vue/components/Patients/Prescriptions/OptiPatientsDetailsPrescriptionEdit'

import OptiPatientsSchedule from '@/js/app/vue/components/Patients/Schedule/OptiPatientsSchedule'
import OptiPatientsScheduleAlert from '@/js/app/vue/components/Patients/Schedule/OptiPatientsScheduleAlert'

import OptiPatientEmailPreview from '@/js/app/vue/components/Patients/Emails/OptiPatientEmailPreview'

import OptiCouponsDetails from '@/js/app/vue/components/Coupons/OptiCouponsDetails'
//Hoya
import OptiCatalogHoya from '@/js/app/vue/components/Catalog/Hoya/OptiCatalogHoya'
import HoyaDailyReportDetails from '@/js/app/vue/components/Catalog/Hoya/HoyaDailyReportDetails'
import OptiHoyaOptionsEdit from '@/js/app/vue/components/Dictionary/Hoya/OptiHoyaOptionsEdit'
import OptiCatalogGraph from '@/js/app/vue/components/Catalog/Graph/OptiCatalogGraph'

// Specifications
import OptiTaskSpecification from '@/js/app/vue/components/Specifications/Tasks/OptiTaskSpecification'
import OptiTaskSpecificationCreate from '@/js/app/vue/components/Specifications/Tasks/OptiTaskSpecificationCreate'
import OptiSaleSpecification from '@/js/app/vue/components/Specifications/Sales/OptiSaleSpecification'
import OptiSaleSpecificationCreate from '@/js/app/vue/components/Specifications/Sales/OptiSaleSpecificationCreate'
import OptiRegenerationSpecification from '@/js/app/vue/components/Specifications/Regenerations/OptiRegenerationSpecification'
import OptiRegenerationSpecificationCreate
    from '@/js/app/vue/components/Specifications/Regenerations/OptiRegenerationSpecificationCreate'
import OptiTaskTurnBackSpecification from '@/js/app/vue/components/Specifications/Tasks/OptiTaskTurnBackSpecification'

// Settings
import OptiSettingsGeneral from '@/js/app/vue/components/Settings/General/OptiSettingsGeneral'
import OptiSettingsOptometristsCalibrate from '@/js/app/vue/components/Settings/Optometrists/OptiSettingsOptometristsCalibrate'

// Settings offer
import OptiSettingsOfferDifference from '@/js/app/vue/components/Settings/Offer/OptiSettingsOfferDifference'
import OptiSettingsOfferTranslations from '@/js/app/vue/components/Settings/Offer/Translations/OptiSettingsOfferTranslations'
import OptiSettingsOfferQuestionsEdit from '@/js/app/vue/components/Settings/Offer/Questions/OptiSettingsOfferQuestionsEdit'

// Optometrists
import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'
import OptiOptometristsDetailsPrevious from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsPrevious'

import OptiOptometristsDetailsSummary from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsSummary'
import OptiOptometristsDetailsRefraction from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsRefraction'

import OptiOptometristsDetailsLensCalculator from '@/js/app/vue/components/Optometrists/Details/Lens/OptiOptometristsDetailsLensCalculator'
import OptiOptometristsDetailsLensControlVisitCheckVisus from '@/js/app/vue/components/Optometrists/Details/Lens/OptiOptometristsDetailsLensControlVisitCheckVisus'
import OptiButtonEfronCompare from '@/js/app/vue/components/Optometrists/Details/Lens/Efron/OptiButtonEfronCompare'

import OptiOptometristsDetailsSurveyHistory from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsSurveyHistory'

//Errors
import OptiErrorsAdd from '@/js/app/vue/components/Errors/OptiErrorsAdd'
import OptiErrorsDetails from '@/js/app/vue/components/Errors/OptiErrorsDetails'
import OptiErrorsChangelog from '@/js/app/vue/components/Errors/OptiErrorsChangelog'

//Tickets
import OptiTicketsDetails from '@/js/app/vue/components/Warehouse/Tickets/OptiTicketsDetails'
import OptiTicketsAdd from '@/js/app/vue/components/Warehouse/Tickets/OptiTicketsAdd'

//Transfers
import OptiTransferCreate from '@/js/app/vue/components/Warehouse/Transfers/OptiTransferCreate'
import OptiTransferDetails from '@/js/app/vue/components/Warehouse/Transfers/OptiTransferDetails'

import OptiTransfersDepartmentsLock
    from '@/js/app/vue/components/Warehouse/Transfers/Lock/OptiTransfersDepartmentsLock'

//Test
import OptiTestDetails from '@/js/app/vue/components/Test/OptiTestDetails'
import OptiTestWarning from '@/js/app/vue/components/Test/OptiTestWarning'

//Orders
import OptiOrdersDetails from '@/js/app/vue/components/Warehouse/Orders/Details/OptiOrdersDetails'

//Shipping
import OptiShippingCreate from '@/js/app/vue/components/Shipping/Create/OptiShippingCreate'

//Shipments
import OptiShipmentCreate from '@/js/app/vue/components/Warehouse/Shipments/Create/OptiShipmentCreate'
import OptiShipmentDetails from '@/js/app/vue/components/Warehouse/Shipments/OptiShipmentDetails'

//OrdersWz
import OptiOrdersWzDetails from '@/js/app/vue/components/Warehouse/Orders/WZ/OptiOrdersWzDetails'
import OptiOrdersWzAddEdit from '@/js/app/vue/components/Warehouse/Orders/WZ/OptiOrdersWzAddEdit'

//TasksWz
import OptiTasksWzDetails from '@/js/app/vue/components/Tasks/Wz/OptiTasksWzDetails'

//TasksPrintouts
import OptiTasksPrintoutDetails from '@/js/app/vue/components/Tasks/OptiTasksPrintoutDetails'

//Suppliers
import OptiSuppliersEdit from '@/js/app/vue/components/Dictionary/Suppliers/OptiSuppliersEdit'
import OptiSuppliersAdd from '@/js/app/vue/components/Dictionary/Suppliers/OptiSuppliersAdd'

//Supply
import OptiSupplyOrderByAdminAssign from '@/js/app/vue/components/Warehouse/Supply/OptiSupplyOrderByAdminAssign'
import OptiSupplyList from '@/js/app/vue/components/Warehouse/Supply/OptiSupplyList'
import OptiSupplyExpected from '@/js/app/vue/components/Warehouse/Supply/OptiSupplyExpected'

//ContactPlus
import OptiContactPlusAdd from '@/js/app/vue/components/Patients/ContactPlus/OptiContactPlusAdd'

//Selections
import OptiProductsSelection from '@/js/app/vue/components/Selection/OptiProductsSelection.vue'

// Bookkeeping
import OptiBookkeepingExpensesAdd from '@/js/app/vue/components/Bookkeeping/Expenses/Add/OptiBookkeepingExpensesAdd'
import OptiBookkeepingExpensesEdit from '@/js/app/vue/components/Bookkeeping/Expenses/OptiBookkeepingExpensesEdit'
import OptiSmileMoreFiles from '@/js/app/vue/components/Chart/OptiSmileMoreFiles'
import OptiBookkeepingArchiveAdd from '@/js/app/vue/components/Bookkeeping/OptiBookkeepingArchiveAdd'

// Marketing
import OptiMarketingAdd from '@/js/app/vue/components/Marketing/OptiMarketingAdd'

//Supplier
import OptiSupplierProductInfoImport from '@/js/app/vue/components/Supplier/OptiProductInfoImport.vue'
import OptiProductsSelectionImport from '@/js/app/vue/components/Selection/OptiProductsSelectionImport.vue'

// Dictionary
import OptiDictionaryDoctorAdd from '@/js/app/vue/components/Dictionary/Doctors/OptiDictionaryDoctorAdd'
import OptiDictionaryDoctorEdit from '@/js/app/vue/components/Dictionary/Doctors/OptiDictionaryDoctorEdit'
import OptiDictionaryDoctorDelete from '@/js/app/vue/components/Dictionary/Doctors/OptiDictionaryDoctorDelete'

import OptiDictionaryProductAttributesAdd from '@/js/app/vue/components/Dictionary/ProductAttributes/OptiDictionaryProductAttributesAdd'
import OptiDictionaryProductAttributesEdit from '@/js/app/vue/components/Dictionary/ProductAttributes/OptiDictionaryProductAttributesEdit'

import OptiDictionaryReclamationServicesAdd from '@/js/app/vue/components/Dictionary/ReclamationServices/OptiDictionaryReclamationServicesAdd'
import OptiDictionaryReclamationServicesEdit from '@/js/app/vue/components/Dictionary/ReclamationServices/OptiDictionaryReclamationServicesEdit'
import OptiDictionaryReclamationServicesDelete from '@/js/app/vue/components/Dictionary/ReclamationServices/OptiDictionaryReclamationServicesDelete'

import OptiDictionaryRegenerationTypeAdd from '@/js/app/vue/components/Dictionary/RegenerationTypes/OptiDictionaryRegenerationTypeAdd'
import OptiDictionaryRegenerationTypeEdit from '@/js/app/vue/components/Dictionary/RegenerationTypes/OptiDictionaryRegenerationTypeEdit'
import OptiDictionaryRegenerationTypeDelete from '@/js/app/vue/components/Dictionary/RegenerationTypes/OptiDictionaryRegenerationTypeDelete'

import OptiDictionaryTrainingTypeAdd from '@/js/app/vue/components/Dictionary/TrainingTypes/OptiDictionaryTrainingTypeAdd'
import OptiDictionaryTrainingTypeEdit from '@/js/app/vue/components/Dictionary/TrainingTypes/OptiDictionaryTrainingTypeEdit'

import OptiDictionaryPaymentsAdd from '@/js/app/vue/components/Dictionary/Payments/OptiDictionaryPaymentsAdd'
import OptiDictionaryPaymentsEdit from '@/js/app/vue/components/Dictionary/Payments/OptiDictionaryPaymentsEdit'

import OptiDictionaryTaskExtraAdd from '@/js/app/vue/components/Dictionary/TaskExtras/OptiDictionaryTaskExtraAdd'
import OptiDictionaryTaskExtraEdit from '@/js/app/vue/components/Dictionary/TaskExtras/OptiDictionaryTaskExtraEdit'
import OptiDictionaryTaskExtraDelete from '@/js/app/vue/components/Dictionary/TaskExtras/OptiDictionaryTaskExtraDelete'

import OptiDictionaryPictogramsIconAdd from "@/js/app/vue/components/Dictionary/Pictograms/Icons/OptiDictionaryPictogramsIconAdd"
import OptiDictionaryPictogramsIconEdit from "@/js/app/vue/components/Dictionary/Pictograms/Icons/OptiDictionaryPictogramsIconEdit"

import OptiDictionaryPictogramsCoatingsEdit from '@/js/app/vue/components/Dictionary/Pictograms/OptiDictionaryPictogramsCoatingsEdit'

import OptiDictionarySeasonsEdit from '@/js/app/vue/components/Dictionary/Seasons/OptiDictionarySeasonsEdit'

import OptiDictionarySpecialistAdd from '@/js/app/vue/components/Dictionary/Specialists/OptiDictionarySpecialistAdd'
import OptiDictionarySpecialistEdit from '@/js/app/vue/components/Dictionary/Specialists/OptiDictionarySpecialistEdit'
import OptiDictionarySpecialistDelete from '@/js/app/vue/components/Dictionary/Specialists/OptiDictionarySpecialistDelete'

import OptiDictionaryStarProtocolAdd from '@/js/app/vue/components/Dictionary/starprotocol/OptiDictionaryStarProtocolAdd'

import OptiDictionaryPreviousLensesContactLensesAdd from '@/js/app/vue/components/Dictionary/PreviousLensesContactLenses/OptiDictionaryPreviousLensesContactLensesAdd'
import OptiDictionaryPreviousLensesContactLensesEdit from '@/js/app/vue/components/Dictionary/PreviousLensesContactLenses/OptiDictionaryPreviousLensesContactLensesEdit'

import OptiAssembliesEdit from '@/js/app/vue/components/Dictionary/OptiAssembliesEdit'

//Packs
import OptiPackAdd from "@/js/app/vue/components/Packs/OptiPackAdd";
import OptiPackEdit from "@/js/app/vue/components/Packs/OptiPackEdit";

// Reports
import OptiReportExcelHistoryDownloading from '@/js/app/vue/components/Reports/ExcelHistory/OptiReportExcelHistoryDownloading'
import OptiReportTasksSales from '@/js/app/vue/components/Reports/OptiReportTasksSales'
import OptiReportStarLoox from '@/js/app/vue/components/Reports/OptiReportStarLoox'
import OptiReportRanking from '@/js/app/vue/components/Reports/OptiReportRanking'
import OptiReportSnapshots from '@/js/app/vue/components/Reports/OptiReportSnapshots'
import OptiReportTasks from '@/js/app/vue/components/Reports/OptiReportTasks'
import OptiReportForecast from '../vue/components/Reports/OptiReportForecast'
import OptiReportWarehouseSales from '@/js/app/vue/components/Reports/Warehouse/OptiReportWarehouseSales'
import OptiReportWarehouseTasksTurnBack from '@/js/app/vue/components/Reports/Warehouse/OptiReportWarehouseTasksTurnBack'
import OptiReportWarehouseManuallyChanges from '@/js/app/vue/components/Reports/Warehouse/OptiReportWarehouseManuallyChanges'
import OptiReportWarehouseProducts from '@/js/app/vue/components/Reports/Warehouse/OptiReportWarehouseProducts'
import OptiReportBudgetsDepartments from '@/js/app/vue/components/Reports/OptiReportBudgetsDepartments'
import OptiReportEntries from '@/js/app/vue/components/Reports/Entries/OptiReportEntries'
import OptiReportPromotions from '@/js/app/vue/components/Reports/OptiReportPromotions'
import OptiReportOffers from '@/js/app/vue/components/Reports/Offers/OptiReportOffers'
import OptiSmileMoreValidations from '@/js/app/vue/components/Warehouse/Orders/Validations/OptiSmileMoreValidations.vue'
//Layout
import OptiLayoutUnit from '@/js/app/vue/components/Layout/OptiLayoutUnit'
import OptiLayoutProducts from '@/js/app/vue/components/Layout/OptiLayoutProducts'

import OptiLayoutRangeAdd from '@/js/app/vue/components/Layout/Ranges/OptiLayoutRangeAdd'
import OptiLayoutRangeEdit from '@/js/app/vue/components/Layout/Ranges/OptiLayoutRangeEdit'
import OptiLayoutRangeDelete from '@/js/app/vue/components/Layout/Ranges/OptiLayoutRangeDelete'

import OptiLayoutContactLensesEdit from '@/js/app/vue/components/Layout/ContactLenses/OptiLayoutContactLensesEdit.vue'

//Register
import OptiRegisterExpenseCurrentAdd from '@/js/app/vue/components/Register/expense/OptiRegisterExpenseCurrentAdd';

import OptiReceipt from '@/js/app/vue/components/Register/Receipt/OptiReceipt'
import OptiRegisterReturnTaskAccount from '@/js/app/vue/components/Register/Return/OptiRegisterReturnTaskAccount'

//Entries
import OptiEntriesMissingDays from '@/js/app/vue/components/Entries/OptiEntriesMissingDays'
import OptiEntriesAdd from '@/js/app/vue/components/Entries/OptiEntriesAdd'

//Downloading
import OptiDownloadingDetails from '@/js/app/vue/components/Downloading/OptiDownloadingDetails'

//eshop
import OptiEshopProductDetail from '@/js/app/vue/components/eShop/OptiEshopProductDetail'
import OptiEShopOrderDetails from '@/js/app/vue/components/eShop/Orders/OptiEShopOrderDetails'
import OptiEShopCustomerDetails from '@/js/app/vue/components/eShop/Customers/Details/OptiEShopCustomerDetails.vue'
import OptiEshopAttributes from '@/js/app/vue/components/eShop/Attributes/OptiEshopAttributes'
import OptiEShopMigrate from '@/js/app/vue/components/eShop/OptiEShopMigrate'

//Events
import OptiEventsUpload from '@/js/app/vue/components/OptiEventsUpload'

class Registrar {
    constructor(module) {
        this.module = module
    }

    __lcFirst(str) {
        str += ''
        let firstChar = str.charAt(0).toLowerCase()
        return firstChar + str.substr(1)
    }

    register(definition) {
        let name = this.__lcFirst(definition.name)
        this.module.directive(name, ['createVueComponent', createVueComponent => createVueComponent(Vue.component(definition.name, definition))])
        return this
    }
}

export default function registerVueDirectives(module) {
    (new Registrar(module))

        // selecty
        .register(OptiSelectDepartment)
        .register(OptiSelectPatient)
        .register(OptiSelectCity)
        .register(OptiSelectCategoryPriceGroup)
        .register(OptiSelectPermissions)

        // checkbox
        .register(OptiCheckboxUser)
        .register(OptiCheckboxDepartment)

        //// Menus
        .register(OptiLeftMenu)
        .register(OptiSearchMenu)
        .register(OptiSearchResult)
        .register(OptiDashboard)

        // Messages
        .register(OptiMessageMember)

        // Receipts
        .register(OptiReceiptNotice)
        .register(OptiReceiptNoticeValue)

        // Sales
        .register(OptiSaleSearch)

        // Rate
        .register(OptiRate)
        .register(OptiReportOffersRate)

        .register(OptiAlertRedirect)

        // Reclamations
        .register(OptiReclamationTaskAdd)
        .register(OptiReclamationTaskEdit)
        .register(OptiReclamationTaskDetails)

        .register(OptiReclamationSaleAdd)
        .register(OptiReclamationSaleEdit)
        .register(OptiReclamationSaleDetails)

        // Regenerations
        .register(OptiRegenerationDetails)
        .register(OptiRegenerationNotes)
        .register(OptiRegenerationAdd)
        .register(OptiRegenerationEdit)

        .register(BonusShow)
        .register(OptiBonusEdit)
        .register(OptiSummaryEntriesReport)

        // Buttons
        .register(OptiButtonToggle)
        .register(OptiButtonHistory)
        .register(OptiButtonPrescription)
        .register(OptiButtonReportRate)

        // Payments
        .register(OptiPayment)
        .register(OptiPaymentShow)

        // Charts
        .register(OptiChartEmployeeCorrectedReachedBudget)
        .register(OptiChartDepartmentBudget)
        .register(OptiChartSummaryBudget)
        .register(OptiChartDiscounts)
        .register(OptiSmileMoreFiles)

        // Schedule
        .register(OptiScheduleNavigation)
        .register(OptiScheduleWeek)
        .register(OptiScheduleMonthQuarter)
        .register(OptiScheduleMonthExcel)

        // Timer
        .register(OptiTimer)
        .register(OptiCountdown)

        // Tasks
        .register(OptiTaskDetails)

        .register(OptiTaskComparePreview)

        // Test
        .register(OptiTestWarning)
        .register(OptiTestDetails)

        // Smile / More
        .register(OptiSmileMoreDetails)

        // Products
        .register(OptiProductDetails)
        .register(OptiProductInfo)
        .register(OptiDepositoryInfo)
        .register(OptiProductEdit)
        .register(OptiProductExport)
        .register(OptiProductImport)
        .register(OptiProductDelivery)
        .register(OptiProductPriceChangeEdit)
        .register(OptiProductPriceChangeAdd)
        .register(OptiProductPriceChangeImport)
        .register(OptiProductPriceChangeProduct)
        .register(OptiProductStockChangeEdit)
        .register(OptiProductLp)

        // Inventories
        .register(OptiInventoryDetails)
        .register(OptiInventoryAdd)
        .register(OptiInventoryTasksInProgress)

        .register(OptiInventoryQuickDetails)
        .register(OptiInventoryQuickAdd)

        // Others
        .register(OptiEnum)
        .register(OptiNotes)
        .register(OptiScrollTop)
        .register(OptiTaskIcon)
        .register(OptiPatient)
        .register(OptiDownloading)
        .register(OptiRefId)
        .register(OptiPreviousLenses)
//       .register(OptiTable)
        .register(OptiDimmer)

        // całe moduły

        // Carts
        .register(OptiCarts)
        .register(OptiCartSaleAdd)
        .register(OptiCartOrderAdd)
        .register(OptiCartSendbackAdd)

        .register(OptiSendbackDetails)

        // Users
        .register(OptiUserAdd)
        .register(OptiUserEdit)

        // Users Permission Extras
        .register(OptiUsersPermissionExtraAdd)
        .register(OptiUsersPermissionExtraEdit)

        // Users Exchanges
        .register(OptiUsersExchangeAdd)
        .register(OptiUsersExchangeEdit)
        .register(OptiUsersExchangeDetails)

        // Users Trainings
        .register(OptiUsersTrainingAdd)
        .register(OptiUsersTrainingEdit)
        .register(OptiUsersTrainingDetails)
        .register(OptiUsersTrainingSchedule)

        // Roles
        .register(OptiRoleAdd)
        .register(OptiRoleEdit)
        .register(OptiRoleAssign)
        .register(OptiRoleExceptionAdd)

        // Departments
        .register(OptiDepartmentsGroupAdd)
        .register(OptiDepartmentsGroupEdit)

        .register(OptiDepartmentsListAdd)
        .register(OptiDepartmentsListEdit)

        .register(OptiDepartmentsOfficeAdd)
        .register(OptiDepartmentsOfficeDetails)
        .register(OptiDepartmentsOfficeEdit)

        //ContactPlus
        .register(OptiContactPlusAdd)

        //Selections
        .register(OptiProductsSelection)

        //Hoya
        .register(OptiCatalogHoya)
        .register(HoyaDailyReportDetails)
        .register(OptiHoyaOptionsEdit)
        .register(OptiCatalogGraph)

        //Patients
        .register(OptiPatientFind)
        .register(OptiPatientAdd)
        .register(OptiPatientEdit)

        //Patients Details
        .register(OptiPatientsDetails)

        .register(OptiPatientsDetailsPrescriptionsMatching)
        .register(OptiPatientLoyalityCard)
        .register(OptiPatientsDetailsLoyalityCardsOrder)
        .register(OptiPatientsDetailsLoyalityCardsEventAdd)
        .register(OptiPatientsDetailsLoyalityCardsEventEdit)

        .register(OptiPatientsDetailsPrescriptionAdd)
        .register(OptiPatientsDetailsPrescriptionEdit)

        // Patients Schedule
        .register(OptiPatientsSchedule)
        .register(OptiPatientsScheduleAlert)

        .register(OptiPatientEmailPreview)

        // Catalog
        .register(OptiCatalogCategory)
        .register(OptiCatalogCategoryEdit)
        .register(OptiCatalogOffer)
        .register(OptiCatalogOfferRedirect)
        .register(OptiCatalogOfferPreview)
        .register(OptiCatalogPricesConfirm)

        // Coupons
        .register(OptiCouponsDetails)

        //Specifications
        .register(OptiRegenerationSpecification)
        .register(OptiRegenerationSpecificationCreate)
        .register(OptiTaskSpecification)
        .register(OptiTaskSpecificationCreate)
        .register(OptiSaleSpecification)
        .register(OptiSaleSpecificationCreate)
        .register(OptiTaskTurnBackSpecification)

        //Settings
        .register(OptiSettingsGeneral)
        .register(OptiSettingsOptometristsCalibrate)

        //Settings offer
        .register(OptiSettingsOfferDifference)
        .register(OptiSettingsOfferTranslations)
        .register(OptiSettingsOfferQuestionsEdit)

        //Optometrists
        .register(OptiOptometristsDisplayControls)
        .register(OptiOptometristsDetailsPrevious)

        .register(OptiOptometristsDetailsSummary)
        .register(OptiOptometristsDetailsRefraction)

        .register(OptiOptometristsDetailsLensCalculator)
        .register(OptiOptometristsDetailsLensControlVisitCheckVisus)
        .register(OptiButtonEfronCompare)

        .register(OptiOptometristsDetailsSurveyHistory)

        //Errors
        .register(OptiErrorsAdd)
        .register(OptiErrorsDetails)
        .register(OptiErrorsChangelog)

        //Tickets
        .register(OptiTicketsDetails)
        .register(OptiTicketsAdd)

        //Transfers
        .register(OptiTransferCreate)
        .register(OptiTransferDetails)

        .register(OptiTransfersDepartmentsLock)

        //Orders
        .register(OptiOrdersDetails)

        //Shipping
        .register(OptiShippingCreate)

        //Shipments
        .register(OptiShipmentCreate)
        .register(OptiShipmentDetails)

        //OrdersWz
        .register(OptiOrdersWzDetails)
        .register(OptiOrdersWzAddEdit)

        //TasksWz
        .register(OptiTasksWzDetails)

        //TasksPrintouts
        .register(OptiTasksPrintoutDetails)

        //Suppliers
        .register(OptiSuppliersEdit)
        .register(OptiSuppliersAdd)

        //Supply
        .register(OptiSupplyOrderByAdminAssign)
        .register(OptiSupplyList)
        .register(OptiSupplyExpected)

        //Bookkeeping
        .register(OptiBookkeepingExpensesAdd)
        .register(OptiBookkeepingExpensesEdit)
        .register(OptiBookkeepingArchiveAdd)

        //Marketing
        .register(OptiMarketingAdd)

        //Dictionary
        .register(OptiDictionaryDoctorAdd)
        .register(OptiDictionaryDoctorEdit)
        .register(OptiDictionaryDoctorDelete)

        .register(OptiDictionaryProductAttributesAdd)
        .register(OptiDictionaryProductAttributesEdit)

        .register(OptiDictionaryReclamationServicesAdd)
        .register(OptiDictionaryReclamationServicesEdit)
        .register(OptiDictionaryReclamationServicesDelete)

        .register(OptiDictionaryRegenerationTypeAdd)
        .register(OptiDictionaryRegenerationTypeEdit)
        .register(OptiDictionaryRegenerationTypeDelete)

        .register(OptiDictionaryTrainingTypeAdd)
        .register(OptiDictionaryTrainingTypeEdit)

        .register(OptiDictionaryPaymentsAdd)
        .register(OptiDictionaryPaymentsEdit)

        .register(OptiDictionaryTaskExtraAdd)
        .register(OptiDictionaryTaskExtraEdit)
        .register(OptiDictionaryTaskExtraDelete)

        .register(OptiDictionaryPictogramsIconAdd)
        .register(OptiDictionaryPictogramsIconEdit)

        .register(OptiDictionaryPictogramsCoatingsEdit)

        .register(OptiDictionarySeasonsEdit)

        .register(OptiDictionarySpecialistAdd)
        .register(OptiDictionarySpecialistEdit)
        .register(OptiDictionarySpecialistDelete)

        .register(OptiDictionaryStarProtocolAdd)

        .register(OptiDictionaryPreviousLensesContactLensesAdd)
        .register(OptiDictionaryPreviousLensesContactLensesEdit)

        .register(OptiAssembliesEdit)

        //Packs
        .register(OptiPackAdd)
        .register(OptiPackEdit)

        //Reports
        .register(OptiReportExcelHistoryDownloading)
        .register(OptiReportTasksSales)
        .register(OptiReportStarLoox)
        .register(OptiReportRanking)
        .register(OptiReportSnapshots)
        .register(OptiReportTasks)
        .register(OptiReportForecast)
        .register(OptiReportWarehouseSales)
        .register(OptiReportWarehouseTasksTurnBack)
        .register(OptiReportWarehouseManuallyChanges)
        .register(OptiReportWarehouseProducts)
        .register(OptiReportBudgetsDepartments)
        .register(OptiReportEntries)
        .register(OptiReportPromotions)
        .register(OptiReportOffers)
        .register(OptiSmileMoreValidations)

        //Layout
        .register(OptiLayoutUnit)
        .register(OptiLayoutProducts)

        .register(OptiLayoutRangeAdd)
        .register(OptiLayoutRangeEdit)
        .register(OptiLayoutRangeDelete)

        .register(OptiLayoutContactLensesEdit)

        //Register
        .register(OptiRegisterExpenseCurrentAdd)

        .register(OptiReceipt)
        .register(OptiRegisterReturnTaskAccount)

        //Entries
        .register(OptiEntriesMissingDays)
        .register(OptiEntriesAdd)

        //Downloading
        .register(OptiDownloadingDetails)

        //eshop
        .register(OptiEshopProductDetail)
        .register(OptiEShopOrderDetails)
        .register(OptiEShopCustomerDetails)
        .register(OptiEshopAttributes)
        .register(OptiEShopMigrate)

        //Events
        .register(OptiEventsUpload)

        //Supplier
        .register(OptiSupplierProductInfoImport)
        .register(OptiProductsSelectionImport)
}
<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-edit.mr3
            span {{'eShop.order.change_status.HEADER'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .row
                .col-xs-12
                    form.form.form-horizontal
                        .form-group
                            label.col-sm-4.control-label.star-required(for="status") {{'eShop.order.STATUS'|translate}}
                            .col-sm-8
                                select#status.form-control(v-model="form.status")
                                    option(v-for="status in statuses" :disabled="status.id === currentStatus"
                                        :value="status.id") {{status.name|translate}}
                                OptiValidate(f="status" :data="$v.form.status")
                        .form-group
                            label.col-sm-4.control-label.star-required(for="comments") {{'eShop.order.change_status.COMMENTS'|translate}}
                            .col-sm-8
                                textarea#comments.form-control(v-model="form.comments")
                                OptiValidate(f="comments" :data="$v.form.comments")

            OptiDimmer(:active="loading")

        template(#footer)
            OptiButtonSave(:disabled="$v.form.$invalid || loading" @click.native="save")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumEOrderStatus}  from '@/js/app/vue/enums/Enums'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiEShopOrderDetailsDialogStatusChange',
        components: {
            OptiDialog,
            OptiValidate,
            OptiButton,
            OptiDimmer,
            OptiButtonSave
        },
        props: {
            id: {
                type: Number,
                required: true
            },
            currentStatus: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                form: {
                    id: this.id,
                    status: '',
                    comments: ''
                },

                statuses: EnumEOrderStatus.simpleItems(),

                loading: false
            }
        },
        validations: {
            form: {
                status: {
                    required
                },
                comments: {
                    required
                }
            }
        },
        methods: {
            save() {
                this.loading = true

                API.post('e-shop/order/status-change', this.form).then(res => {
                    this.$emit('save', res.data)
                    this.$emit('close')

                    this.$notify.success('eShop.order.change_status.notify.CHANGED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('eShop.order.change_status.notify.error.CHANGED')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 450px;
    }
</style>